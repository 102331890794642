import roles from '@/security/roles';

export const IS_PROD = process.env.NODE_ENV === 'production';
export const IS_DEV = process.env.NODE_ENV === 'development';
export const API_HOST = REACT_APP_ENV === 'prod' ? '/admin' : '/admin';
export const STAGING_HOSTNAME = 'dev-tribe.guanyun.cn';

export const ROLE_TYPES = { ...roles };

export const STATE_BADGES_CSS = {
  committed: 'tailwind-badge',
  refunded: 'tailwind-badge-danger',
  completed: 'tailwind-badge-success',
  draft: 'tailwind-badge-dark',
};

export const ORDER_PAY_TYPES = {
  money: 'money',
  points: 'points',
};

export const ORDER_STATE_KEYS = {
  valid: 'paid,shipped,completed',
  checking_out: 'checking_out',
  paid: 'paid',
  shipped: 'shipped',
  completed: 'completed',
  refunded: 'refunded',
  cancelled: 'cancelled',
};

export const ORDER_TYPES = {
  standard: {
    label: '普通订单',
    value: 'standard',
    color: 'default',
  },
  manual: {
    label: '手工订单',
    value: 'manual',
    color: '#108ee9',
  },
};

export enum MANUAL_ORDER_TYPES {
  '小程序' = '小程序',
  '员工内购' = '员工内购',
  '元气内购' = '元气内购',
  '元气月结' = '元气月结',
}

export const ORDER_STATE_TYPES = {
  [ORDER_STATE_KEYS['valid']]: '有效订单',
  [ORDER_STATE_KEYS['checking_out']]: '待付款',
  [ORDER_STATE_KEYS['paid']]: '已付款',
  [ORDER_STATE_KEYS['shipped']]: '已发货',
  [ORDER_STATE_KEYS['completed']]: '已完成',
  [ORDER_STATE_KEYS['refunded']]: '已退款',
  [ORDER_STATE_KEYS['cancelled']]: '已取消',
};

export const ORDER_STATE_CLS = {
  [ORDER_STATE_KEYS['checking_out']]: 'tailwind-badge-dark',
  [ORDER_STATE_KEYS['paid']]: 'tailwind-badge-success',
  [ORDER_STATE_KEYS['shipped']]: 'tailwind-badge-success',
  [ORDER_STATE_KEYS['completed']]: 'tailwind-badge-success',
  [ORDER_STATE_KEYS['refunded']]: 'tailwind-badge-danger',
  [ORDER_STATE_KEYS['cancelled']]: 'tailwind-badge-danger',
};

export const PAYMENT_STATE_COLORS = {
  已退款: 'tailwind-badge-danger',
  已付款: 'tailwind-badge-success',
  待付款: 'tailwind-badge-dark',
};

export const SHIPMENT_STATE_COLORS = {
  已签收: 'tailwind-badge-success',
  已发货: 'tailwind-badge',
  待发货: 'tailwind-badge-warning',
};

export const REFUND_STATUS = {
  common: {
    label: '正常',
    value: 'common',
  },
  refund_in_request: {
    label: '申请退款',
    value: 'refund_in_request',
  },
  refund_agreed: {
    label: '同意退款',
    value: 'refund_agreed',
  },
  refund_refused: {
    label: '拒绝',
    value: 'refund_refused',
  },
  refund_cancelled: {
    label: '已取消退款',
    value: 'refund_cancelled',
  },
  refund_completed: {
    label: '已退款',
    value: 'refund_completed',
  },
};

export const SCENE_TYPES = {
  only_refund_money: {
    label: '仅退款',
    value: 'only_refund_money',
  },
  refund_all: {
    label: '退款退货',
    value: 'refund_all',
  },
};

export const PUBLISH_STATE_COLORS = {
  已发布: 'tailwind-badge-success',
  未发布: 'tailwind-badge-dark',
};

export const COUPON_TYPES = {
  direct_discount: {
    label: '立减券',
    value: 'direct_discount',
  },
  amount_discount: {
    label: '满减券',
    value: 'amount_discount',
  },
  percent_discount: {
    label: '折扣券',
    value: 'percent_discount',
  },
};
export const COUPON_SCOPE_TYPES = {
  common: {
    label: '通用券',
    value: 0,
  },
  product: {
    label: '产品券',
    value: 1,
  },
  variant: {
    label: '规格券',
    value: 2,
  },
};

export const COUPON_SEND_TYPES = {
  direct: {
    label: '直接赠送',
    value: 0,
  },
  center: {
    label: '领券中心',
    value: 1,
  },
};

export const COUPON_STATUS_TYPES = {
  enabled: {
    label: '启用',
    value: 'enabled',
    color: 'green',
  },
  disabled: {
    label: '已作废',
    value: 'disabled',
    color: 'red',
  },
};

export const ARTICLES_PUBLISH_TYPES = {
  true: {
    label: '已发布',
    value: true,
  },
  false: {
    label: '未发布',
    value: false,
  },
};

export const ARTICLES_STATUS_TYPES = {
  true: {
    label: '是',
    value: 0,
    color: 'green',
  },
  false: {
    label: '否',
    value: 1,
    color: 'default',
  },
};

export const STAFF_TAGS = {
  staff: {
    label: '员工',
    value: 'staff',
  },
  cooperate_staff: {
    label: '元気分销',
    value: 'cooperate_staff',
  },
};

export const BANNER_SCENE_TYPES = {
  home: {
    label: '主推商品',
    value: 'home',
  },
  events: {
    label: '活动模块',
    value: 'events',
  },
  vip: {
    label: 'vip',
    value: 'vip',
  },
};

export const BANNER_KIND_TYPES = {
  inner: {
    label: '小程序',
    value: 'inner',
  },
  outer: {
    label: '网页',
    value: 'outer',
  },
};

export const COLLECTABLE_TYPES = {
  product: 'Product',
  saleService: 'SaleService',
};

export const COLLECTABLE_LABELS = {
  [COLLECTABLE_TYPES.product]: '产品',
  [COLLECTABLE_TYPES.saleService]: '服务',
};

export const COLLECTION_TYPES = {
  public: 'CollectionPublic',
  newcomer: 'CollectionNewcomer',
  private: 'CollectionPrivate',
  vip: 'CollectionVip',
};

export const COLLECTION_OPTIONS = [
  { label: '公开价', value: COLLECTION_TYPES.public },
  { label: '新人价', value: COLLECTION_TYPES.newcomer },
  { label: '特殊价', value: COLLECTION_TYPES.private },
  { label: '老铁价', value: COLLECTION_TYPES.vip },
];

/**
 * 销售服务类型枚举。
 * @readonly
 * @enum {string}
 */
export const SALE_SERVICE_TYPES = {
  /** 公开 */
  publicService: 'PublicService',
  /** 新人 */
  newcomerService: 'NewcomerService',
  /** 特殊 */
  privateService: 'PrivateService',
  /** 老铁 */
  vipService: 'VipService',
  /** 秒杀 */
  flashService: 'FlashService',
  /** 积分 */
  pointService: 'PointService',
};

export const SALE_SERVICE_TAGS = {
  [SALE_SERVICE_TYPES.privateService]: '特价商品',
  [SALE_SERVICE_TYPES.newcomerService]: '新人专享',
  [SALE_SERVICE_TYPES.vipService]: '老铁专享',
  [SALE_SERVICE_TYPES.flashService]: '限时秒杀',
  [SALE_SERVICE_TYPES.pointService]: '积分兑换',
};

/**
 * 销售服务选项对象。
 * @typedef {Object} SaleServiceOption
 * @property {string} value - 服务选项值
 * @property {string} label - 服务选项标签
 * @property {string} desc - 服务选项描述
 */

/**
 * 销售服务选项列表。
 * @type {Array<SaleServiceOption>}
 */
export const SALE_SERVICE_OPTIONS = [
  {
    value: SALE_SERVICE_TYPES.publicService,
    label: '公开价',
    desc: '此规格公开售卖的默认价格',
  },
  {
    value: SALE_SERVICE_TYPES.newcomerService,
    label: '新人价',
    desc: '未下过单的新用户可以购买，并且只能购买一次',
  },
  {
    value: SALE_SERVICE_TYPES.privateService,
    label: '特殊价',
    desc: '没有购买限制，但售价与公开价不同，用于某种特殊用途',
  },
  {
    value: SALE_SERVICE_TYPES.vipService,
    label: '老铁价',
    desc: '核心老铁尊享价格',
  },
  {
    value: SALE_SERVICE_TYPES.flashService,
    label: '秒杀价',
    desc: '限时秒杀价格',
  },
];

/**
 * 销售服务选项映射对象。
 * @type {Object.<string, SaleServiceOption>}
 */
export const SALE_SERVICE_MAPS = SALE_SERVICE_OPTIONS.reduce((pre, option) => {
  // @ts-ignore
  pre[option.value] = {
    label: option.label,
    desc: option.desc,
  };
  return pre;
}, {}) as Record<keyof typeof SALE_SERVICE_TYPES, any>;
